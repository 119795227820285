import Swiper, {Navigation, Pagination} from 'swiper';

Swiper.use([Navigation, Pagination]);

$(document).ready(function () {

  if (typeof coreFunctions.getCookie('cookie_notice') == 'undefined') {
    $('.js--cookie').on('click', function () {
      $('.cookie').hide();
      coreFunctions.setCookie('cookie_notice', 1);
      return false;
    });
  }

  // фиксим модальное окно через окно
  $(document).on('hidden.bs.modal', function (event) {
    if ($('.modal:visible').length) {
      $('body').addClass('modal-open');
    }
  }).on('click', '*[data-bs-target="#modal--check-download"]', function (event) {
    if (typeof dataLayer !== 'undefined') {
      dataLayer.push({'event': 'intentUploadCheck'});
    }
  });

  if ($(".js-carousel-1").length) {
    // init of WEEKLY carousel
    const swiper = new Swiper('.js-carousel-1', {
      loop: true,
      speed: 800,
      pagination: false,
      slidesPerView: 'auto',
      spaceBetween: 30,
      breakpoints: {
        600: {
          slidesPerView: 2,
        },
        850: {
          slidesPerView: 3,
        },
      },
      navigation: {
        nextEl: '#kitchen-next',
        prevEl: '#kitchen-prev',
      },
    });
  }

  if ($(".js-carousel-3").length) {
    // init of WEEKLY carousel
    const swiper3 = new Swiper('.js-carousel-3', {
      loop: true,
      speed: 800,
      pagination: false,
      slidesPerView: 'auto',
      spaceBetween: 30,
      breakpoints: {
        600: {
          slidesPerView: 2,
        },
        850: {
          slidesPerView: 3,
        },
      },
      navigation: {
        nextEl: '#culture-next',
        prevEl: '#culture-prev',
      },
    });
  }

  if ($(".js-carousel-2").length) {
    // init of WEEKLY carousel
    const swiper2 = new Swiper('.js-carousel-2', {
      loop: true,
      speed: 800,
      pagination: false,
      centeredSlides: true,
      slidesPerView: 'auto',
      spaceBetween: 76,
      initialSlide: 1,
      breakpoints: {
        768: {
          pagination: false
        },
      },
      navigation: {
        nextEl: '.weekly__nav-btn-2--next',
        prevEl: '.weekly__nav-btn-2--prev',
      },
    });
  }

  if ($(".modal-recipe__slider").length) {
    var myModalEl = $(".modal--recipes");

    myModalEl.each(function () {
      let currentModal = $(this);
      let currentSlider = currentModal.find('.modal-recipe__slider');
      let currentSwiper;

      currentModal.on('shown.bs.modal', function (event) {
        setTimeout(function () {
          if (!currentSlider.hasClass('swiper-container-initialized')) {
            currentSwiper = new Swiper(currentModal.find('.modal-recipe__slider')[0], {
              loop: false,
              speed: 800,
              pagination: false,
              centeredSlides: false,
              slidesPerView: 1,
              spaceBetween: 0,
              breakpoints: {
                768: {
                  pagination: false
                },
              },
              navigation: {
                nextEl: '.modal-recipe__nav-btn--next',
                prevEl: '.modal-recipe__nav-btn--prev',
              },
            });
          } else {
            currentSwiper.slideTo(0);
          }
        }, 100);
      })
    })
  }

  $('.header__burger').on('click', function () {
    $(this).toggleClass('state-active');
    $("body").toggleClass('show-nav');
  })

  $(".header__nav-link").on('click', function () {
    if ($('body').hasClass('show-nav')) {
      $('body').removeClass('show-nav');
    }
  })

  // var myFullpage = new fullpage('#fullpage', {
  //   licenseKey: '02DC6155-E2E44D08-A168BF50-FE3157FA',
  //   anchors: ['page1', 'page2', 'page3', 'page4', 'page5', 'page6', 'page7', 'page8', 'page9', 'page10'],
  //   menu: '#menu',
  //   onLeave: function (origin, destination, direction) {
  //     $('body').removeClass('menu__open');
  //   }
  // });

  $(".js-cookie-btn").on('click', function (e) {
    e.preventDefault();
    $('.cookie-banner').hide();
  })

  $('.faq__item-header').on('click', function () {
    var currentItem = $(this).parent('.faq__item');

    if (currentItem.hasClass('state-active')) {
      $('.faq__item-content', currentItem).slideUp(300);
      currentItem.removeClass('state-active');
    } else {
      $('.faq__item-content', currentItem).slideDown(300);
      currentItem.addClass('state-active');
    }


  })

  let $body = $('body');

  // events on body
  $body
    .addClass('loaded')
    .on('click', '.js-social--auth', function (e) {
      // авторизация через социалки
      e.preventDefault();

      let href = $(this).attr('href') || $(this).attr('data-href');
      window.open(href, '', 'toolbar=0,status=0,width=626,height=436')
    })
    .on('click', '*[data-gtagcategory]', function (e) {
      // gtag
      var data = $(this).data();
      if (typeof gtag === 'undefined') {
        return false;
      }
      gtag('event', 'click', {
        'event_category': data.gtagcategory,
        'event_label': data.gtaglabel
      });
    });

  // подтверждение аккаунта
  (function () {
    let confirmed = coreFunctions.getParameterByName("confirmed");
    if (confirmed !== '') {
      window.setTimeout(function () {
        $('#modal--registration-congratulation').modal('show');
        coreFunctions.setHistoryState(document.title, "/");
      }, 50);
    }
  })();

  // inputmask
  $('input[data-mask]').each(function () {
    var $t = $(this), mask = $t.data('mask');
    if (typeof $t.attr('disabled') === 'undefined') {
      $t.inputmask('remove').inputmask('' + mask);
    }
  });

  // scroll
  $('.js--scroll').on('click', function () {
    $('html, body').animate({
      scrollTop: $($(this).attr('href')).offset().top
    }, 400);
    return false
  });

  // datepicker
  $.datepicker.regional['ru'] = {
    closeText: 'Закрыть',
    prevText: 'Предыдущий',
    nextText: 'Следующий',
    currentText: 'Сегодня',
    monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    monthNamesShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
    dayNames: ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'],
    dayNamesShort: ['вск', 'пнд', 'втр', 'срд', 'чтв', 'птн', 'сбт'],
    dayNamesMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    weekHeader: 'Не',
    dateFormat: 'dd.mm.yy',
    firstDay: 1,
    isRTL: false,
    changeMonth: true,
    changeYear: true,
    showMonthAfterYear: false,
    yearSuffix: ''
  };
  $.datepicker.setDefaults($.datepicker.regional['ru']);
  $('.datepicker').each(function () {
    let $t = $(this);
    $t.datepicker({
      yearRange: $t.attr('data-year-range')
    });
  });


  // message
  window.showBootstrapMessage = function (params) {
    params = $.extend({
      title: '',
      message: ''
    }, params);

    let $v_modal = $('.modal:visible'),
      $m_modal = $('#modal-message'), $title = $m_modal.find('.modal-title'), $message = $m_modal.find('.modal-body');

    $v_modal.modal('hide');
    $('.modal-backdrop').remove();

    $title.html(params.title);
    $message.html('<p>' + params.message + '</p>');

    $m_modal.modal('show');

    if (typeof params['afterHide'] === 'function') {
      $m_modal.on('hidden.bs.modal', function () {
        $m_modal.off('hidden.bs.modal');
        params.afterHide.call();
      });
    }
  };

  // modal
  window.showBootstrapModal = function (selector) {
    $('.modal:visible').modal('hide');
    $('.modal-backdrop').remove();
    $(selector).modal('show');
  };

  // sms
  $('.js--send_smscode').on('click', function (e) {
    e.preventDefault();

    let $t = $(this), v = $(this.form).find('input[name="user[phone]"]').val();
    if (v.length === 18) {
      $t.attr('disabled', 'disabled');
      let $f = $('.js--form__smscode');
      $f.find('#smscode_user_phone').val(v);
      $f.trigger('submit');

      let interval_left = 30, interval_int = setInterval(function () {
        interval_left--;
        $t.html(interval_left);

        if (interval_left < 1) {
          $t.removeAttr('disabled');
          $t.html($t.data('ovalue'));
          clearInterval(interval_int);
        }
      }, 1000);

      $t.data('ovalue', $t.html());
      $t.html(interval_left);
    }
  });
});
